/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images - Removed
// import LogoAsana from "assets/images/small-logos/logo-asana.svg";
// import logoGithub from "assets/images/small-logos/github.svg";
// import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
// import logoSlack from "assets/images/small-logos/logo-slack.svg";
// import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
// import logoInvesion from "assets/images/small-logos/logo-invision.svg";

export default function data() {
  /* const Project = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" variant="rounded" />
      <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  ); */

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  /* const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Details</MenuItem>
      <MenuItem onClick={closeMenu}>Accept</MenuItem>
      <MenuItem onClick={closeMenu}>Invite</MenuItem>
    </Menu>
  ); */

  return {
    columns: [
      { Header: "challenge", accessor: "challenges", width: "30%", align: "left" },
      { Header: "rewards", accessor: "rewards", align: "left" },
      { Header: "Date", accessor: "date", align: "center" },
      { Header: "enrollment", accessor: "completion", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: [
      {
        challenges: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            Pune Marathon
          </MDTypography>
        ),
        rewards: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            2000
          </MDTypography>
        ),
        date: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            02 Feb 2023
          </MDTypography>
        ),
        completion: <Progress color="info" value={60} />,
        action: (
          <MDTypography component="a" href="#" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
      {
        challenges: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            Panhala - Pavan Khind
          </MDTypography>
        ),
        rewards: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            4000
          </MDTypography>
        ),
        date: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            21 Feb 2023
          </MDTypography>
        ),
        completion: <Progress color="success" value={100} />,
        action: (
          <MDTypography component="a" href="#" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
      {
        challenges: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            Sinhgad Trek
          </MDTypography>
        ),
        rewards: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            1000
          </MDTypography>
        ),
        date: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            21 Mar 2023
          </MDTypography>
        ),
        completion: <Progress color="error" value={30} />,
        action: (
          <MDTypography component="a" href="#" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
      {
        challenges: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            Some Challenge
          </MDTypography>
        ),
        rewards: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            1000
          </MDTypography>
        ),
        date: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            01 May 2023
          </MDTypography>
        ),
        completion: <Progress color="info" value={80} />,
        action: (
          <MDTypography component="a" href="#" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
      {
        challenges: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            Some more difficult challenge
          </MDTypography>
        ),
        rewards: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            5000
          </MDTypography>
        ),
        date: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            01 Jun 2023
          </MDTypography>
        ),
        completion: <Progress color="error" value={0} />,
        action: (
          <MDTypography component="a" href="#" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
      {
        challenges: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            Some more difficult challenge
          </MDTypography>
        ),
        rewards: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            7000
          </MDTypography>
        ),
        date: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            05 Jun 2023
          </MDTypography>
        ),
        completion: <Progress color="success" value={100} />,
        action: (
          <MDTypography component="a" href="#" color="text">
            <Icon>more_vert</Icon>
          </MDTypography>
        ),
      },
    ],
  };
}
