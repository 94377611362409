/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* IMFit.online - v2.1.0
=========================================================

* Product Page: https://www.imfit.online
* Copyright 2022 Pragmatic Business Consultants.

Coded by www.makeitpragmatic.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// @mui material components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Menu item
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function tournaments() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("http://localhost:8085/api/v1/viewChallenges");
      const newData = await response.json();
      setData(newData);
    };
    fetchData();
  }, []);

  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Details</MenuItem>
      <MenuItem onClick={closeMenu}>Register</MenuItem>
      <MenuItem onClick={closeMenu}>Skip</MenuItem>
      <MenuItem onClick={closeMenu}>Invite</MenuItem>
    </Menu>
  );

  const tournamentheader = [
    { Header: "challenge", accessor: "challenge", width: "45%", align: "left" },
    { Header: "participants", accessor: "participants", width: "10%", align: "left" },
    { Header: "rewards", accessor: "rewards", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const tournamentdtls = data.map((tournamentobj) => ({
    challenge: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {tournamentobj.challenge}
      </MDTypography>
    ),
    participants: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {tournamentobj.users}
      </MDTypography>
    ),
    rewards: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {tournamentobj.reward}
      </MDTypography>
    ),
    action: (
      <MDBox color="text" px={2}>
        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
          more_vert
        </Icon>
        {renderMenu}
      </MDBox>
    ),
  }));

  return {
    columns: tournamentheader,
    rows: tournamentdtls,
  };
}
