/**
=========================================================
* IMFit.online - v2.1.0
=========================================================

* Product Page: https://www.imfit.online
* Copyright 2022 Pragmatic Business Consultants.

Coded by www.makeitpragmatic.com

 =========================================================
*
*/

export default {
  labels: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  datasets: { label: "Steps", data: [50, 20, 10, 22, 50, 10, 40] },
};
