/**
=========================================================
* IMFit.online - v2.1.0
=========================================================

* Product Page: https://www.imfit.online
* Copyright 2022 Pragmatic Business Consultants.

Coded by www.makeitpragmatic.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const appBar = {
  defaultProps: {
    color: "transparent",
  },

  styleOverrides: {
    root: {
      boxShadow: "none",
    },
  },
};

export default appBar;
