/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* IMFit.online - v2.1.0
=========================================================

* Product Page: https://www.imfit.online
* Copyright 2022 Pragmatic Business Consultants.

Coded by www.makeitpragmatic.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";

// import axios from "axios";

export default function leaderboard() {
  // const [firstName] = useState("Jane Doe");
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("http://localhost:8085/api/v1/users");
      const newData = await response.json();
      setData(newData);
    };
    fetchData();
  }, []);

  const titles = [
    { Header: "ranking", accessor: "rank", align: "left" },
    { Header: "first name", accessor: "fname", align: "left" },
    { Header: "last name", accessor: "lname", align: "center" },
    { Header: "email", accessor: "email", align: "center" },
    { Header: "points", accessor: "rewards", align: "center" },
  ];

  const rowdata = data.map((leaderobj) => ({
    rank: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {leaderobj.id}
      </MDTypography>
    ),
    fname: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {leaderobj.firstName}
      </MDTypography>
    ),
    lname: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {leaderobj.lastName}
      </MDTypography>
    ),
    email: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {leaderobj.email}
      </MDTypography>
    ),
    rewards: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {leaderobj.points}
      </MDTypography>
    ),
  }));

  return {
    columns: titles,
    rows: rowdata,
  };
}
