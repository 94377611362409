/**
=========================================================
* IMFit.online - v2.1.0
=========================================================

* Product Page: https://www.imfit.online
* Copyright 2022 Pragmatic Business Consultants.

Coded by www.makeitpragmatic.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function OTPValidation({ data }) {
  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [otp, setOtp] = React.useState("");
  const [error, setError] = React.useState("");

  const navigate = useNavigate();

  const isEven = () => {
    let sumOfDigit = 0;
    let temp = parseInt(otp, 10);
    while (temp > 0) {
      sumOfDigit += temp % 10;
      temp = parseInt(temp / 10, 10);
    }
    return sumOfDigit % 2 === 0;
  };

  const errorMessage = (message) => {
    setError(message);
    setTimeout(() => setError(""), 2000);
  };

  const handleSubmit = () => {
    console.log(data);
    if (otp.length !== 6) {
      errorMessage("Digits in OTP number should be equal to 6");
      return;
    }
    if (isEven()) navigate("/dashboard");
    else {
      errorMessage("Invalid OTP!");
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Please enter your OTP
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" method="post">
            <MDBox mb={2}>
              <MDInput
                type="text"
                autoComplete="off"
                label="OTP"
                fullWidth
                onChange={(event) => setOtp(event.target.value)}
                placeholder="Enter 6 digit OTP"
                error={error.length > 0}
                helperText={error}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp; Don&apos;t ask me on this device again.
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                <Icon>lock</Icon>&nbsp; validate
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default OTPValidation;
