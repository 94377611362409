/**
=========================================================
* IMFit.online - v2.1.0
=========================================================

* Product Page: https://www.imfit.online
* Copyright 2022 Pragmatic Business Consultants.

Coded by www.makeitpragmatic.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base breakpoints for the Material Dashboard 2 PRO React.
 * You can add new breakpoints using this file.
 * You can customized the breakpoints for the entire Material Dashboard 2 PRO React using thie file.
 */

const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};

export default breakpoints;
