/**
=========================================================
* IMFit.online - v2.1.0
=========================================================

* Product Page: https://www.imfit.online
* Copyright 2022 Pragmatic Business Consultants.

Coded by www.makeitpragmatic.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect } from "react";
// react-router-dom components
import { Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";

// import { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
// import Checkbox from "@mui/material/Checkbox";

// Custom components
import MDAutoComplete from "components/MDAutocomplete";
import MDDialog from "components/MDDialog";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

import MultiFactorAutentication from "../sign-in/multi-factor-auth";

function Cover() {
  const [decision, setDecision] = React.useState("N");
  // const phoneNumber = `+${countryISDCode + phone}`;
  const isRequired = true;

  const [isFormVisible, setIsFormVisible] = React.useState(true);
  // const { watch } = useFormContext("");

  const methods = useForm({
    mode: "all",
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = methods;

  const countryObject = methods.watch("country");

  useEffect(() => {
    setValue("isPnPAccepted", decision);
  }, [decision, setValue]);

  const onSubmit = (data) => {
    console.log(data);
    setIsFormVisible(false);
  };

  return (
    <>
      {isFormVisible ? (
        <CoverLayout image={bgImage}>
          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={3}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Join Now
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1}>
                Welcome to the Fitness Club
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <FormProvider {...methods}>
                <MDBox
                  component="form"
                  method="post"
                  role="form"
                  noValidate
                  autoComplete="off"
                  action="/dashboard"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="First Name"
                      name="firstName"
                      autoComplete="off"
                      variant="standard"
                      required={isRequired}
                      {...register("firstName", {
                        required: "First name is required.",
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please enter valid First Name",
                        },
                        minLength: {
                          value: 3,
                          message: "First name must be at least 3 characters long.",
                        },
                        maxLength: {
                          value: 30,
                          message: "First name cannot be more than 30 characters.",
                        },
                      })}
                      error={!!errors.firstName}
                      helperText={errors.firstName?.message}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Last Name"
                      name="lastName"
                      autoComplete="off"
                      variant="standard"
                      required={isRequired}
                      {...register("lastName", {
                        required: "Last Name is required.",
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Please enter valid Last Name",
                        },
                        minLength: {
                          value: 3,
                          message: "Last Name must be at least 3 characters long.",
                        },
                        maxLength: {
                          value: 30,
                          message: "Last Name cannot be more than 30 characters.",
                        },
                      })}
                      error={!!errors.lastName}
                      helperText={errors.lastName?.message}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      name="email"
                      autoComplete="off"
                      required={isRequired}
                      variant="standard"
                      {...register("email", {
                        required: "Email is required.",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Please enter valid Email address.",
                        },
                      })}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDAutoComplete control={methods.control} countryObject={countryObject} />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="phone"
                      label="Phone"
                      name="phone"
                      autoComplete="off"
                      required={isRequired}
                      variant="standard"
                      {...register("phone", {
                        required: "Phone number is required.",
                        pattern: {
                          value: /^(0|[1-9]\d*)(\.\d+)?$/,
                          message: "Please enter valid phone number",
                        },
                      })}
                      error={!!errors.phone}
                      helperText={errors.phone?.message}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Password"
                      name="password"
                      autoComplete="off"
                      required={isRequired}
                      variant="standard"
                      {...register("password", {
                        required: "Password is required.",
                        pattern: {
                          value: /^(?=.*[0-9])(?=.*[!@#$%^&*.,])[a-zA-Z0-9!@#$%^&*.,]{8,32}$/,
                          message:
                            "Password must be between 8 and 32 characters, contains one uppercase, one lowercase, one number, and a special character.",
                        },
                      })}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      fullWidth
                    />
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={-1}>
                    {/*
                    <Checkbox
                      type="checkbox"
                      name="isPnPAccepted"
                      id="isPnPAccepted"
                      checked={decision === "Y"}
                      {...register("isPnPAccepted")}
                    />
                    */}
                    <MDDialog setDecision={setDecision} />
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      type="submit"
                      fullWidth
                      disabled={decision === "N"}
                    >
                      Sign up
                    </MDButton>
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                      Already have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/authentication/sign-in"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign In
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </FormProvider>
            </MDBox>
          </Card>
        </CoverLayout>
      ) : (
        <MultiFactorAutentication />
      )}
      ;
    </>
  );
}

export default Cover;
